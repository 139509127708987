"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
/** @jsx jsx */
const core_1 = require("@emotion/core");
const styles_1 = require("../styles");
const Adornment = (_a) => {
    var { icon, label, isFocused, hasError, onClick } = _a, rest = __rest(_a, ["icon", "label", "isFocused", "hasError", "onClick"]);
    let srcIcon = icon.default;
    if (hasError && icon.error) {
        srcIcon = icon.error;
    }
    else if (isFocused && icon.active) {
        srcIcon = icon.active;
    }
    const onClickAdornment = () => {
        if (rest.onFocus) {
            rest.onFocus();
        }
        if (onClick) {
            onClick();
        }
    };
    return (core_1.jsx("div", { css: styles_1.adornmentCss(!!onClick), role: "button", onKeyPress: onClickAdornment, onClick: onClickAdornment, tabIndex: onClick ? 0 : -1 },
        core_1.jsx("img", Object.assign({ src: srcIcon, alt: label }, rest))));
};
exports.default = Adornment;
