"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const digital_blueprint_models_1 = require("@clearsummit/digital-blueprint-models");
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const lodash_1 = require("lodash");
const shared_reducers_1 = require("./shared-reducers");
exports.ACTIONS = {
    DETAIL_REQUEST: 'CHANGE_REQUEST/DETAIL_REQUEST',
    DETAIL_SUCCESS: 'CHANGE_REQUEST/DETAIL_SUCCESS',
    DETAIL_FAILURE: 'CHANGE_REQUEST/DETAIL_FAILURE',
    LIST_REQUEST: 'CHANGE_REQUEST/LIST_REQUEST',
    LIST_SUCCESS: 'CHANGE_REQUEST/LIST_SUCCESS',
    FILTER_CHANGE: 'CHANGE_REQUEST/FILTER_CHANGE_REQUEST',
    REVIEW_REQUEST: 'CHANGE_REQUEST/REVIEW_REQUEST',
    REVIEW_SUCCESS: 'CHANGE_REQUEST/REVIEW_SUCCESS',
};
exports.INITIAL_STATE = {
    changeRequests: [],
    filters: {
        panelId: null,
        page: 1,
        perPage: 10,
    },
    count: null,
    currentChange: null
};
const listChangeRequestsRequest = (state, data) => lodash_1.isEqual(state.filters, data) ? Object.assign({}, state) : Object.assign(Object.assign({}, state), { filters: data });
const listChangeRequestsSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { changeRequests: lodash_1.get(payload, 'data.data', state.changeRequests).map((change) => new digital_blueprint_models_1.ChangeRequest(change)), count: lodash_1.get(payload, 'data.count', state.count) }));
const filterChangeRequest = (state, data) => lodash_1.isEqual(state.filters, data) ? Object.assign({}, state) : Object.assign(Object.assign({}, state), { filters: data });
const reviewChangeRequest = shared_reducers_1.noOp;
const reviewChangeSuccess = shared_reducers_1.noOp;
const getChangeRequest = shared_reducers_1.noOp;
const getChangeSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { currentChange: new digital_blueprint_models_1.ChangeRequest(payload.data) }));
exports.reduxSet = {
    listChangeRequestsRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_REQUEST, listChangeRequestsRequest),
    listChangeRequestsSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_SUCCESS, listChangeRequestsSuccess),
    filterChangeRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.FILTER_CHANGE, filterChangeRequest),
    reviewChangeRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.REVIEW_REQUEST, reviewChangeRequest),
    reviewChangeSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.REVIEW_SUCCESS, reviewChangeSuccess),
    getChangeRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DETAIL_REQUEST, getChangeRequest),
    getChangeSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DETAIL_SUCCESS, getChangeSuccess),
    getChangeFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DETAIL_FAILURE, shared_reducers_1.noOp)
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
