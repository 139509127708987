"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeDetailSchema = exports.ChangePasswordSchema = exports.ChangeInformationSchema = exports.SignUpSchema = exports.defaultEmployeeRoleSchema = exports.defaultLastNameSchema = exports.defaultFirstNameSchema = exports.ResetPasswordChangeSchema = exports.ResetPasswordSchema = exports.LoginSchema = exports.defaultPhoneNumberSchema = exports.defaultCheckboxSchema = exports.defaultConfirmPassword = exports.defaultPasswordSchema = exports.defaultEmailSchema = void 0;
const yup = __importStar(require("yup"));
const constants_1 = __importStar(require("@/constants"));
const FieldNames = __importStar(require("./field-names"));
exports.defaultEmailSchema = yup
    .string()
    .email(constants_1.default.login.pleaseEnterAValid)
    .required()
    .label('Email');
exports.defaultPasswordSchema = yup
    .string()
    .min(8, constants_1.default.signup.beAtLeast8)
    .max(50, constants_1.default.signup.notExceed50)
    .matches(constants_1.Regex.oneNumber, constants_1.default.signup.containAtLeast1Number)
    .matches(constants_1.Regex.oneSpecialChar, constants_1.default.signup.containAtLeast1Special)
    .test('empty-check', constants_1.default.signup.passwordMustBe, (password) => (password === null || password === void 0 ? void 0 : password.length) !== 0)
    .label('Password');
exports.defaultConfirmPassword = yup
    .string()
    .oneOf([yup.ref('password'), null], constants_1.default.signup.passwordsDoNotMatch)
    .label('Password');
exports.defaultCheckboxSchema = yup
    .boolean()
    .oneOf([true])
    .required();
exports.defaultPhoneNumberSchema = yup
    .string()
    .matches(constants_1.Regex.phoneNumberFormat, constants_1.default.employeeDetail.invalidPhone)
    .matches(constants_1.Regex.phoneNumberMinDigits, constants_1.default.employeeDetail.invalidPhone);
exports.LoginSchema = yup.object().shape({
    [FieldNames.LoginFieldNames.username]: exports.defaultEmailSchema,
    [FieldNames.LoginFieldNames.password]: exports.defaultPasswordSchema.required()
});
exports.ResetPasswordSchema = yup.object().shape({
    [FieldNames.ResetPasswordFieldNames.username]: exports.defaultEmailSchema
});
exports.ResetPasswordChangeSchema = yup.object().shape({
    [FieldNames.ResetPasswordChangeFieldNames.password]: exports.defaultPasswordSchema,
    [FieldNames.ResetPasswordChangeFieldNames.confirmPassword]: exports.defaultConfirmPassword.required()
});
exports.defaultFirstNameSchema = yup
    .string()
    .required()
    .label(constants_1.default.signup.firstName);
exports.defaultLastNameSchema = yup
    .string()
    .label(constants_1.default.signup.lastName);
exports.defaultEmployeeRoleSchema = yup
    .string()
    .oneOf([
    'DEPARTMENT_LEAD',
    'HOUSE_ENGINEER',
    'SENIOR_CONTRACTOR',
    'CONTRACTOR'
])
    .required();
exports.SignUpSchema = yup.object().shape({
    [FieldNames.SignUpFieldNames.password]: exports.defaultPasswordSchema.required(),
    [FieldNames.SignUpFieldNames.tos]: exports.defaultCheckboxSchema
});
exports.ChangeInformationSchema = yup.object().shape({
    [FieldNames.ChangeInformationFieldNames.firstName]: exports.defaultFirstNameSchema,
    [FieldNames.ChangeInformationFieldNames.lastName]: exports.defaultLastNameSchema,
    [FieldNames.ChangeInformationFieldNames.email]: exports.defaultEmailSchema
});
exports.ChangePasswordSchema = yup.object().shape({
    [FieldNames.ChangePasswordFieldNames.oldPassword]: exports.defaultPasswordSchema.label('Current Password').required(),
    [FieldNames.ChangePasswordFieldNames.newPassword]: exports.defaultPasswordSchema.label('New Password').required(),
    [FieldNames.ChangePasswordFieldNames.confirmPassword]: yup
        .string()
        .oneOf([yup.ref(FieldNames.ChangePasswordFieldNames.newPassword), null], constants_1.default.signup.passwordsDoNotMatch)
        .label('Confirm Password')
        .required()
});
exports.EmployeeDetailSchema = yup.object().shape({
    [FieldNames.EmployeeDetailFieldNames.firstName]: exports.defaultFirstNameSchema.label(constants_1.default.employeeDetail.labelFirstName),
    [FieldNames.EmployeeDetailFieldNames.lastName]: exports.defaultLastNameSchema.label(constants_1.default.employeeDetail.labelLastName).required(),
    [FieldNames.EmployeeDetailFieldNames.email]: exports.defaultEmailSchema.label(constants_1.default.employeeDetail.labelEmail),
    [FieldNames.EmployeeDetailFieldNames.phone]: exports.defaultPhoneNumberSchema.label(constants_1.default.employeeDetail.labelPhone),
    [FieldNames.EmployeeDetailFieldNames.role]: exports.defaultEmployeeRoleSchema.label(constants_1.default.employeeDetail.labelRole),
    [FieldNames.EmployeeDetailFieldNames.jobTitle]: yup.string().label(constants_1.default.employeeDetail.labelJobTitle),
});
exports.default = {};
