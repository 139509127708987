"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useToggle = (value) => {
    const [toggleValue, setToggleValue] = react_1.useState(value);
    const onToggle = (val) => {
        if (typeof val === 'boolean') {
            setToggleValue(val);
        }
        else {
            setToggleValue(!toggleValue);
        }
    };
    return [toggleValue, onToggle];
};
exports.default = useToggle;
