"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getUserStore = (store) => store.user;
const getUser = (store) => getUserStore(store).user;
const getPending = (store) => getUserStore(store).pending;
const getCurrentOrg = (store) => getUserStore(store).selectedOrganization;
const getRequests = (store) => getUserStore(store).requests;
exports.default = {
    getUser,
    getPending,
    getCurrentOrg,
    getRequests
};
