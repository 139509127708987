"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiEmployeeDelete = exports.ApiEmployeeUpdate = exports.ApiEmployeeCreate = exports.ApiEmployeeList = void 0;
const services_1 = require("@/helpers/services");
const __1 = require("..");
// eslint-disable-next-line camelcase
const ApiEmployeeList = (payload) => {
    const { perPage } = payload, data = __rest(payload, ["perPage"]);
    return {
        data: Object.assign(Object.assign({}, data), { per_page: perPage }),
        serviceKey: services_1.endpoints.employeeList,
        successActionCreator: __1.ActionCreators.employees.employeeListSuccess.dispatch
    };
};
exports.ApiEmployeeList = ApiEmployeeList;
const ApiEmployeeCreate = (data) => ({
    data,
    serviceKey: services_1.endpoints.employeeCreate,
    successActionCreator: __1.ActionCreators.employees.employeeCreateSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.employees.employeeSetErrors.dispatch
});
exports.ApiEmployeeCreate = ApiEmployeeCreate;
const ApiEmployeeUpdate = (data) => ({
    data,
    serviceKey: services_1.endpoints.employeeUpdate,
    successActionCreator: __1.ActionCreators.employees.employeeUpdateSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.employees.employeeSetErrors.dispatch
});
exports.ApiEmployeeUpdate = ApiEmployeeUpdate;
const ApiEmployeeDelete = (data) => ({
    data,
    serviceKey: services_1.endpoints.employeeDelete,
    successActionCreator: __1.ActionCreators.employees.employeeDeleteSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.employees.employeeSetErrors.dispatch
});
exports.ApiEmployeeDelete = ApiEmployeeDelete;
