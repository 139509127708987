"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
const Styles = {
    container: (disabled) => core_1.css `
    opacity: ${!disabled ? 1 : 0.5};
  `,
    label: (activeColor, disabled) => core_1.css `
    :checked + label {
        cursor: ${!disabled ? 'pointer' : 'not-allowed'};
        span:before {
            background-color: ${activeColor};
        }
    }
    `
};
exports.default = Styles;
