"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const digital_blueprint_models_1 = require("@clearsummit/digital-blueprint-models");
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const lodash_1 = require("lodash");
const shared_reducers_1 = require("./shared-reducers");
exports.ACTIONS = {
    CHANGE_PANELS_FILTERS: 'PANELS/CHANGE_PANELS_FILTERS',
    LIST_PANELS_REQUEST: 'PANELS/LIST_PANELS_REQUEST',
    LIST_PANELS_SUCCESS: 'PANELS/LIST_PANELS_SUCCESS',
    DELETE_PANEL_REQUEST: 'PANELS/DELETE_PANEL_REQUEST',
    DELETE_PANEL_SUCCESS: 'PANELS/DELETE_PANEL_SUCCESS',
    GET_PANEL_REQUEST: 'PANELS/GET_PANEL_REQUEST',
    GET_PANEL_SUCCESS: 'PANELS/GET_PANEL_SUCCESS',
    GET_PANEL_FAILURE: 'PANELS/GET_PANEL_FAILURE',
    APPROVE_PANEL_REQUEST: 'PANELS/APPROVE_PANEL_REQUEST',
    APPROVE_PANEL_SUCCESS: 'PANELS/APPROVE_PANEL_SUCCESS',
};
exports.INITIAL_STATE = {
    panels: [],
    filters: {
        ordering: '-updated_at',
        page: 1,
        perPage: 10,
    },
    count: null,
    currentPanel: null,
};
const changePanelsFilters = (state, payload) => (!lodash_1.isEqual(payload, state.filters) ? Object.assign(Object.assign({}, state), { filters: payload }) : Object.assign({}, state));
const listPanelsRequest = (state, payload) => (!lodash_1.isEqual(payload, state.filters) ? Object.assign(Object.assign({}, state), { filters: payload }) : Object.assign({}, state));
const listPanelsSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { panels: lodash_1.get(payload, 'data.data', state.panels).map((panel) => new digital_blueprint_models_1.Panel(panel)), count: lodash_1.get(payload, 'data.count', state.count) }));
const deletePanelRequest = shared_reducers_1.noOp;
const deletePanelSuccess = shared_reducers_1.noOp;
const getPanelRequest = (state) => (Object.assign({}, state));
const getPanelSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { currentPanel: new digital_blueprint_models_1.Panel(payload.data) }));
const approvePanelRequest = (state) => (Object.assign({}, state));
const approvePanelSuccess = (state) => (Object.assign({}, state));
exports.reduxSet = {
    changePanelsFilters: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_PANELS_FILTERS, changePanelsFilters),
    listPanelsRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_PANELS_REQUEST, listPanelsRequest),
    listPanelsSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_PANELS_SUCCESS, listPanelsSuccess),
    deletePanelRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_PANEL_REQUEST, deletePanelRequest),
    deletePanelSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_PANEL_SUCCESS, deletePanelSuccess),
    getPanelRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PANEL_REQUEST, getPanelRequest),
    getPanelSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PANEL_SUCCESS, getPanelSuccess),
    getPanelFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.GET_PANEL_FAILURE, shared_reducers_1.noOp),
    approvePanelRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.APPROVE_PANEL_REQUEST, approvePanelRequest),
    approvePanelSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.APPROVE_PANEL_SUCCESS, approvePanelSuccess)
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
