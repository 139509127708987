"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiReviewChangeRequest = exports.ApiListChangeRequests = exports.ApiGetChangeRequest = void 0;
const services_1 = require("@/helpers/services");
const __1 = require("..");
const ApiGetChangeRequest = (data) => ({
    data,
    serviceKey: services_1.endpoints.getChangeRequest,
    successActionCreator: __1.ActionCreators.changeRequest.getChangeSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.changeRequest.getChangeFailure.dispatch
});
exports.ApiGetChangeRequest = ApiGetChangeRequest;
const ApiListChangeRequests = (payload
// eslint-disable-next-line camelcase
) => {
    const { changeType, perPage } = payload, data = __rest(payload, ["changeType", "perPage"]);
    return {
        data: Object.assign(Object.assign({}, data), { change_type: changeType, page_size: perPage }),
        serviceKey: services_1.endpoints.listChangeRequests,
        successActionCreator: __1.ActionCreators.changeRequest.listChangeRequestsSuccess.dispatch
    };
};
exports.ApiListChangeRequests = ApiListChangeRequests;
// eslint-disable-next-line camelcase
const ApiReviewChangeRequest = (payload) => {
    const { changeIds } = payload, data = __rest(payload, ["changeIds"]);
    return {
        data: Object.assign(Object.assign({}, data), { change_ids: changeIds }),
        serviceKey: services_1.endpoints.reviewChangeRequest,
        successActionCreator: __1.ActionCreators.changeRequest.reviewChangeSuccess.dispatch
    };
};
exports.ApiReviewChangeRequest = ApiReviewChangeRequest;
