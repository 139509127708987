"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.footerContainerCss = void 0;
const core_1 = require("@emotion/core");
const footerContainerCss = (hasError) => core_1.css `
  margin-top: ${hasError ? '-8px' : '-30px'};
  ${hasError && `
      span {
        margin-top: -22px;
      }
    `}
`;
exports.footerContainerCss = footerContainerCss;
