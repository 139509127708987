// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-screens-employees-employee-detail-___styles__container___yrakm{display:flex;flex-direction:column}.src-screens-employees-employee-detail-___styles__container___yrakm .src-screens-employees-employee-detail-___styles__containerHeader___1tw1C{display:flex;flex-direction:column;margin-top:24px}\n", "",{"version":3,"sources":["webpack://src/screens/employees/employee-detail/styles.scss"],"names":[],"mappings":"AAAouC,oEAAW,YAAY,CAAC,qBAAqB,CAAC,8IAA4B,YAAY,CAAC,qBAAqB,CAAC,eAAe","sourcesContent":[":export{black:#202126;blue:#1c5898;boxShadowGrey:rgba(11,9,44,0.2);btnActive:#eda323;btnDefault:#f1d335;btnDisabled:#9d9ea4;btnGhostActive:rgba(0,149,226,0.1);btnGhostDefault:rgba(0,0,0,0);btnGhostHover:rgba(100,193,241,0.1);btnHover:#efba2c;btnSecondaryActive:#414248;btnSecondaryDefault:#fff;btnSecondaryDisabled:#f9faff;btnSecondaryHover:#f9faff;darkGrey:#606167;error:#da1414;errorBackground:rgba(218,20,20,0.1);faintBlue:rgba(28,88,152,0.05);green:#59bf28;grey:#9d9ea4;lightBlue:#2fb4f9;lightGrey:#f9faff;olive:#20cb9a;orange:#fe7a24;panelBlack:#000;panelBlue:#2f80ed;panelBrown:#90705f;panelOrange:#ffc074;panelRed:#fa0909;panelYellow:#f7fd04;peach:#fdc;primary:#f1d335;red:#fa1931;sea:rgba(47,181,249,0.1);secondary:#202126;secondaryBackground:rgba(32,33,38,0.1);success:#5aca75;successBackground:rgba(90,202,117,0.1);tertinary:#1c7cb8;tertinaryBackground:rgba(28,124,184,0.1);warning:#ff8f39;warningBackground:rgba(255,143,57,0.1);white:#fff}:export{bannerHeight:48px;bannerWidth:327px;inputHeight:38px;l:24px;m:20px;mediumFont:16px;s:16px;slimButtonHeight:24px;smallestFont:10px;smallestSpacer:5px;spacer:15px;standardFont:14px;standardLineHeight:22px;tablet:768px;titleFont:20px;xl:28px;xs:12px;xxl:32px;xxs:8px;xxxl:40px;xxxs:4px;xxxxl:64px}.container{display:flex;flex-direction:column}.container .containerHeader{display:flex;flex-direction:column;margin-top:24px}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#202126",
	"blue": "#1c5898",
	"boxShadowGrey": "rgba(11,9,44,0.2)",
	"btnActive": "#eda323",
	"btnDefault": "#f1d335",
	"btnDisabled": "#9d9ea4",
	"btnGhostActive": "rgba(0,149,226,0.1)",
	"btnGhostDefault": "rgba(0,0,0,0)",
	"btnGhostHover": "rgba(100,193,241,0.1)",
	"btnHover": "#efba2c",
	"btnSecondaryActive": "#414248",
	"btnSecondaryDefault": "#fff",
	"btnSecondaryDisabled": "#f9faff",
	"btnSecondaryHover": "#f9faff",
	"darkGrey": "#606167",
	"error": "#da1414",
	"errorBackground": "rgba(218,20,20,0.1)",
	"faintBlue": "rgba(28,88,152,0.05)",
	"green": "#59bf28",
	"grey": "#9d9ea4",
	"lightBlue": "#2fb4f9",
	"lightGrey": "#f9faff",
	"olive": "#20cb9a",
	"orange": "#fe7a24",
	"panelBlack": "#000",
	"panelBlue": "#2f80ed",
	"panelBrown": "#90705f",
	"panelOrange": "#ffc074",
	"panelRed": "#fa0909",
	"panelYellow": "#f7fd04",
	"peach": "#fdc",
	"primary": "#f1d335",
	"red": "#fa1931",
	"sea": "rgba(47,181,249,0.1)",
	"secondary": "#202126",
	"secondaryBackground": "rgba(32,33,38,0.1)",
	"success": "#5aca75",
	"successBackground": "rgba(90,202,117,0.1)",
	"tertinary": "#1c7cb8",
	"tertinaryBackground": "rgba(28,124,184,0.1)",
	"warning": "#ff8f39",
	"warningBackground": "rgba(255,143,57,0.1)",
	"white": "#fff",
	"bannerHeight": "48px",
	"bannerWidth": "327px",
	"inputHeight": "38px",
	"l": "24px",
	"m": "20px",
	"mediumFont": "16px",
	"s": "16px",
	"slimButtonHeight": "24px",
	"smallestFont": "10px",
	"smallestSpacer": "5px",
	"spacer": "15px",
	"standardFont": "14px",
	"standardLineHeight": "22px",
	"tablet": "768px",
	"titleFont": "20px",
	"xl": "28px",
	"xs": "12px",
	"xxl": "32px",
	"xxs": "8px",
	"xxxl": "40px",
	"xxxs": "4px",
	"xxxxl": "64px",
	"container": "src-screens-employees-employee-detail-___styles__container___yrakm",
	"containerHeader": "src-screens-employees-employee-detail-___styles__containerHeader___1tw1C"
};
export default ___CSS_LOADER_EXPORT___;
