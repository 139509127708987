"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const digital_blueprint_models_1 = require("@clearsummit/digital-blueprint-models");
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const shared_reducers_1 = require("@/redux/shared-reducers");
// User Actions
exports.ACTIONS = {
    START_UP: 'START_UP',
    SET_USER_SESSION: 'SET_USER_SESSION',
    CREATE_USER: 'CREATE_USER',
    FAILURE_CREATE_USER: 'FAILURE_CREATE_USER',
    CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
    HYDRATE_USER_SUCCESS: 'USER/HYDRATE_USER_SUCCESS',
    HYDRATE_USER_FAILURE: 'USER/HYDRATE_USER_FAILURE',
    STORE_USER: 'STORE_USER',
    LOGOUT: 'LOGOUT',
    HYDRATE_USER: 'HYDRATE_USER',
    START_REQUEST: 'USER/START_REQUEST',
    END_REQUEST: 'USER/END_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USER/RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_CHANGE_SUCCESS: 'USER/RESET_PASSWORD_CHANGE_SUCCESS',
    CHANGE_SELECTED_ORGANIZATION: 'USER/CHANGE_SELECTED_ORGANIZATION',
    CHANGE_INFORMATION_REQUEST: 'USER/CHANGE_INFORMATION_REQUEST',
    CHANGE_INFORMATION_SUCCESS: 'USER/CHANGE_INFORMATION_SUCCESS',
    CHANGE_INFORMATION_FAILURE: 'USER/CHANGE_INFORMATION_FAILURE',
    CHANGE_PASSWORD_REQUEST: 'USER/CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USER/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USER/CHANGE_PASSWORD_FAILURE',
    S3_UPLOAD_LOGO_REQUEST: 'USER/S3_UPLOAD_LOGO_REQUEST',
    S3_UPLOAD_LOGO_SUCCESS: 'USER/S3_UPLOAD_LOGO_SUCCESS',
    S3_UPLOAD_LOGO_FAILURE: 'USER/S3_UPLOAD_LOGO_FAILURE',
    UPDATE_ORGANIZATION_LOGO_REQUEST: 'USER/UPDATE_ORGANIZATION_LOGO_REQUEST',
    UPDATE_ORGANIZATION_LOGO_SUCCESS: 'USER/UPDATE_ORGANIZATION_LOGO_SUCCESS',
    UPDATE_ORGANIZATION_LOGO_FAILURE: 'USER/UPDATE_ORGANIZATION_LOGO_FAILURE',
    UPDATE_ORGANIZATION_REQUEST: 'USER/UPDATE_ORGANIZATION_REQUEST',
    UPDATE_ORGANIZATION_SUCCESS: 'USER/UPDATE_ORGANIZATION_SUCCESS',
    UPDATE_ORGANIZATION_FAILURE: 'USER/UPDATE_ORGANIZATION_FAILURE',
    CLEAR_FILE_TO_UPLOAD: 'USER/CLEAR_FILE_TO_UPLOAD',
    SET_REQUEST_PENDING: 'USER/SET_REQUEST_PENDING',
    SET_REQUEST_ERROR: 'USER/SET_REQUEST_ERROR',
    CLEAR_REQUEST_ERROR: 'USER/CLEAR_REQUEST_ERROR'
};
exports.INITIAL_STATE = {
    user: null,
    pending: false,
    error: null,
    selectedOrganization: null,
    fileToUpload: null,
    requests: {
        logo: null,
        info: null,
        password: null
    }
};
const startUp = (state) => state;
const signUpFailure = (state, payload) => (Object.assign(Object.assign({}, state), { error: payload.details }));
const updateUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data || state.user }));
const storeUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.user }));
const logOut = (state) => (Object.assign(Object.assign({}, state), { user: new digital_blueprint_models_1.User(), selectedOrganization: null }));
const resetPasswordSuccess = (state) => (Object.assign({}, state));
const storeRegisteredUser = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data }));
const changeSelectedOrganization = (state, payload) => (Object.assign(Object.assign({}, state), { selectedOrganization: payload }));
const changeInformationRequest = shared_reducers_1.noOp;
const changeInformationSuccess = (state, payload) => (Object.assign(Object.assign({}, state), { user: payload.data.user }));
const changePasswordRequest = shared_reducers_1.noOp;
const changePasswordSuccess = shared_reducers_1.noOp;
const S3UploadLogoRequest = (state, payload) => (Object.assign(Object.assign({}, state), { fileToUpload: payload }));
const clearFileToUpload = (state) => (Object.assign(Object.assign({}, state), { fileToUpload: null }));
const updateOrganizationRequest = shared_reducers_1.noOp;
const updateOrganizationSuccess = shared_reducers_1.noOp;
const setRequestPending = (state, pending) => (Object.assign(Object.assign({}, state), { requests: Object.assign(Object.assign({}, state.requests), { [pending.key]: Object.assign(Object.assign({}, state.requests[pending.key]), { pending: pending.value }) }) }));
const setRequestError = (state, error) => (Object.assign(Object.assign({}, state), { requests: Object.assign(Object.assign({}, state.requests), { [error.key]: { pending: false, errors: error.data } }) }));
const clearRequestError = (state, key) => (Object.assign(Object.assign({}, state), { requests: Object.assign(Object.assign({}, state.requests), { [key]: Object.assign(Object.assign({}, state.requests[key]), { errors: null }) }) }));
exports.reduxSet = {
    startUp: radio_dispatch_1.dispatchReducer(exports.ACTIONS.START_UP, startUp),
    loginSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_USER_SESSION, shared_reducers_1.noOp),
    signUpSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_USER, storeRegisteredUser),
    hydrateUserSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.HYDRATE_USER_SUCCESS, updateUser),
    hydrateUserFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.HYDRATE_USER_FAILURE, logOut),
    logOut: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LOGOUT, logOut),
    signUpFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.FAILURE_CREATE_USER, signUpFailure),
    resetPasswordSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_PASSWORD_SUCCESS, resetPasswordSuccess),
    resetPasswordChangeSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.RESET_PASSWORD_CHANGE_SUCCESS, resetPasswordSuccess),
    dispatchStoreUser: radio_dispatch_1.dispatchReducer(exports.ACTIONS.STORE_USER, storeUser),
    changeSelectedOrganization: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_SELECTED_ORGANIZATION, changeSelectedOrganization),
    changeInformationRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_INFORMATION_REQUEST, changeInformationRequest),
    changeInformationSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_INFORMATION_SUCCESS, changeInformationSuccess),
    changeInformationFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_INFORMATION_FAILURE, shared_reducers_1.noOp),
    changePasswordRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_PASSWORD_REQUEST, changePasswordRequest),
    changePasswordSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_PASSWORD_SUCCESS, changePasswordSuccess),
    changePasswordFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CHANGE_PASSWORD_FAILURE, shared_reducers_1.noOp),
    S3UploadLogoRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.S3_UPLOAD_LOGO_REQUEST, S3UploadLogoRequest),
    S3UploadLogoSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.S3_UPLOAD_LOGO_SUCCESS, shared_reducers_1.noOp),
    S3UploadLogoFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.S3_UPLOAD_LOGO_FAILURE, shared_reducers_1.noOp),
    clearFileToUpload: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLEAR_FILE_TO_UPLOAD, clearFileToUpload),
    updateOrganizationLogoRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_LOGO_REQUEST, shared_reducers_1.noOp),
    updateOrganizationLogoSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_LOGO_SUCCESS, shared_reducers_1.noOp),
    updateOrganizationLogoFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_LOGO_FAILURE, shared_reducers_1.noOp),
    updateOrganizationRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_REQUEST, updateOrganizationRequest),
    updateOrganizationSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_SUCCESS, updateOrganizationSuccess),
    updateOrganizationFailure: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_ORGANIZATION_FAILURE, shared_reducers_1.noOp),
    setRequestPending: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_REQUEST_PENDING, setRequestPending),
    setRequestError: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_REQUEST_ERROR, setRequestError),
    clearRequestError: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLEAR_REQUEST_ERROR, clearRequestError)
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
