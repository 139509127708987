"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.reduxSet = exports.INITIAL_STATE = exports.ACTIONS = void 0;
const radio_dispatch_1 = require("@clearsummit/radio-dispatch");
const shared_reducers_1 = require("./shared-reducers");
exports.ACTIONS = {
    LIST_REQUEST: 'EMPLOYEES/LIST_REQUEST',
    LIST_SUCCESS: 'EMPLOYEES/LIST_SUCCESS',
    SET_CURRENT: 'EMPLOYEES/SET_CURRENT',
    CLEAR_CURRENT: 'EMPLOYEES/CLEAR_CURRENT',
    CREATE_REQUEST: 'EMPLOYEES/CREATE_REQUEST',
    CREATE_SUCCESS: 'EMPLOYEES/CREATE_SUCCESS',
    UPDATE_REQUEST: 'EMPLOYEES/UPDATE_REQUEST',
    UPDATE_SUCCESS: 'EMPLOYEES/UPDATE_SUCCESS',
    DELETE_REQUEST: 'EMPLOYEES/DELETE_REQUEST',
    DELETE_SUCCESS: 'EMPLOYEES/DELETE_SUCCESS',
    SET_ERRORS: 'EMPLOYEES/SET_ERRORS',
    CLEAR_ERRORS: 'EMPLOYEES/CLEAR_ERRORS',
    SET_REQUEST_NAME: 'EMPLOYEES/SET_REQUEST_NAME',
    CLEAR_REQUEST_NAME: 'EMPLOYEES/CLEAR_REQUEST_NAME',
};
exports.INITIAL_STATE = {
    employees: [],
    count: null,
    next: null,
    previous: null,
    currentEmployee: null,
    requestEmployee: null,
    errors: {}
};
const employeeListSuccess = (state, payload) => {
    const { data: employees, count, previous, next } = payload.data;
    return Object.assign(Object.assign({}, state), { employees, count, previous, next });
};
const employeeSetCurrent = (state, payload) => (Object.assign(Object.assign({}, state), { currentEmployee: payload }));
const employeeClearCurrent = (state) => (Object.assign(Object.assign({}, state), { currentEmployee: null }));
const employeeSetErrors = (state, payload) => (Object.assign(Object.assign({}, state), { errors: payload.data }));
const employeeClearErrors = (state) => (Object.assign(Object.assign({}, state), { errors: {} }));
const employeeSetRequestName = (state, name) => (Object.assign(Object.assign({}, state), { requestEmployee: name }));
const employeeClearRequestName = (state) => (Object.assign(Object.assign({}, state), { requestEmployee: null }));
exports.reduxSet = {
    employeeListRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_REQUEST, shared_reducers_1.noOp),
    employeeListSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.LIST_SUCCESS, employeeListSuccess),
    employeeSetCurrent: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_CURRENT, employeeSetCurrent),
    employeeClearCurrent: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLEAR_CURRENT, employeeClearCurrent),
    employeeCreateRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_REQUEST, shared_reducers_1.noOp),
    employeeCreateSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CREATE_SUCCESS, shared_reducers_1.noOp),
    employeeUpdateRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_REQUEST, shared_reducers_1.noOp),
    employeeUpdateSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.UPDATE_SUCCESS, shared_reducers_1.noOp),
    employeeDeleteRequest: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_REQUEST, shared_reducers_1.noOp),
    employeeDeleteSuccess: radio_dispatch_1.dispatchReducer(exports.ACTIONS.DELETE_SUCCESS, shared_reducers_1.noOp),
    employeeClearErrors: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLEAR_ERRORS, employeeClearErrors),
    employeeSetErrors: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_ERRORS, employeeSetErrors),
    employeeSetRequestName: radio_dispatch_1.dispatchReducer(exports.ACTIONS.SET_REQUEST_NAME, employeeSetRequestName),
    employeeClearRequestName: radio_dispatch_1.dispatchReducer(exports.ACTIONS.CLEAR_REQUEST_NAME, employeeClearRequestName)
};
const reducer = (state = Object.assign({}, exports.INITIAL_STATE), action) => radio_dispatch_1.runReducers(state, action, exports.reduxSet);
exports.reducer = reducer;
