"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationRoles = exports.ChangeStatuses = exports.ChangeTypes = exports.ParentBreakers = exports.ColorSchemes = exports.FuseSizeUnits = exports.FeederSizeLugs = exports.FeederSizeTypes = exports.Statuses = exports.VoltagePhases = exports.TemplateSizes = exports.PowerTypes = void 0;
var PowerTypes;
(function (PowerTypes) {
    PowerTypes["NORMAL"] = "NORMAL";
    PowerTypes["EMERGENCY"] = "EMERGENCY";
})(PowerTypes = exports.PowerTypes || (exports.PowerTypes = {}));
var TemplateSizes;
(function (TemplateSizes) {
    TemplateSizes[TemplateSizes["CKT_12"] = 12] = "CKT_12";
    TemplateSizes[TemplateSizes["CKT_18"] = 18] = "CKT_18";
    TemplateSizes[TemplateSizes["CKT_30"] = 30] = "CKT_30";
    TemplateSizes[TemplateSizes["CKT_42"] = 42] = "CKT_42";
    TemplateSizes[TemplateSizes["CKT_60"] = 60] = "CKT_60";
    TemplateSizes[TemplateSizes["CKT_84"] = 84] = "CKT_84";
})(TemplateSizes = exports.TemplateSizes || (exports.TemplateSizes = {}));
var VoltagePhases;
(function (VoltagePhases) {
    VoltagePhases["VP_120_208"] = "VP_120_208";
    VoltagePhases["VP_208"] = "VP_208";
    VoltagePhases["VP_277_480"] = "VP_277_480";
    VoltagePhases["VP_120_240"] = "VP_120_240";
    VoltagePhases["VP_240"] = "VP_240";
    VoltagePhases["VP_480"] = "VP_480";
})(VoltagePhases = exports.VoltagePhases || (exports.VoltagePhases = {}));
var Statuses;
(function (Statuses) {
    Statuses["APPROVED"] = "APPROVED";
    Statuses["PENDING"] = "PENDING";
    Statuses["DENIED"] = "DENIED";
})(Statuses = exports.Statuses || (exports.Statuses = {}));
var FeederSizeTypes;
(function (FeederSizeTypes) {
    FeederSizeTypes["TOP_FED"] = "TOP_FED";
    FeederSizeTypes["BOTTOM_FED"] = "BOTTOM_FED";
})(FeederSizeTypes = exports.FeederSizeTypes || (exports.FeederSizeTypes = {}));
var FeederSizeLugs;
(function (FeederSizeLugs) {
    FeederSizeLugs["SUB_FEED"] = "SUB_FEED";
    FeederSizeLugs["FEED_THROUGH"] = "FEED_THROUGH";
})(FeederSizeLugs = exports.FeederSizeLugs || (exports.FeederSizeLugs = {}));
var FuseSizeUnits;
(function (FuseSizeUnits) {
    FuseSizeUnits["AMP"] = "AMP";
    FuseSizeUnits["VOLT"] = "VOLT";
})(FuseSizeUnits = exports.FuseSizeUnits || (exports.FuseSizeUnits = {}));
var ColorSchemes;
(function (ColorSchemes) {
    ColorSchemes["BLUE_RED_BLACK"] = "BLUE_RED_BLACK";
    ColorSchemes["BLACK_RED_BLUE"] = "BLACK_RED_BLUE";
    ColorSchemes["BROWN_ORANGE_YELLOW"] = "BROWN_ORANGE_YELLOW";
})(ColorSchemes = exports.ColorSchemes || (exports.ColorSchemes = {}));
var ParentBreakers;
(function (ParentBreakers) {
    ParentBreakers["MLO"] = "MLO";
    ParentBreakers["MAIN_BREAKER"] = "MAIN_BREAKER";
})(ParentBreakers = exports.ParentBreakers || (exports.ParentBreakers = {}));
var ChangeTypes;
(function (ChangeTypes) {
    ChangeTypes["PANEL"] = "PANEL";
    ChangeTypes["LAYOUT"] = "LAYOUT";
    ChangeTypes["METADATA"] = "METADATA";
})(ChangeTypes = exports.ChangeTypes || (exports.ChangeTypes = {}));
var ChangeStatuses;
(function (ChangeStatuses) {
    ChangeStatuses["PENDING"] = "PENDING";
    ChangeStatuses["APPROVED"] = "APPROVED";
    ChangeStatuses["DENIED"] = "DENIED";
})(ChangeStatuses = exports.ChangeStatuses || (exports.ChangeStatuses = {}));
var OrganizationRoles;
(function (OrganizationRoles) {
    OrganizationRoles["DEPARTMENT_LEAD"] = "DEPARTMENT_LEAD";
    OrganizationRoles["HOUSE_ENGINEER"] = "HOUSE_ENGINEER";
    OrganizationRoles["SENIOR_CONTRACTOR"] = "SENIOR_CONTRACTOR";
    OrganizationRoles["CONTRACTOR"] = "CONTRACTOR";
})(OrganizationRoles = exports.OrganizationRoles || (exports.OrganizationRoles = {}));
