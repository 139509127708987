"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getChangeRequestStore = (store) => store.changeRequest;
const getChangeRequests = (store) => getChangeRequestStore(store).changeRequests;
const getCount = (store) => getChangeRequestStore(store).count;
const getFilters = (store) => getChangeRequestStore(store).filters;
const getCurrentChangeRequest = (store) => getChangeRequestStore(store).currentChange;
exports.default = {
    getChangeRequests,
    getCount,
    getFilters,
    getCurrentChangeRequest
};
