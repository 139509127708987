"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiUpdateOrganization = exports.ApiUpdateOrganizationLogo = exports.ApiS3UploadLogo = exports.ApiChangePassword = exports.ApiChangeInformation = exports.logoutPayload = exports.hydrateUserPayload = exports.signUpPayload = exports.resetPasswordChangePayload = exports.resetPasswordPayload = exports.verifyEmailPostPayload = exports.verifyEmailPayload = exports.loginPayload = void 0;
const services_1 = require("@/helpers/services");
const __1 = require("..");
const loginPayload = (data) => ({
    serviceKey: services_1.endpoints.login,
    successActionCreator: __1.ActionCreators.user.loginSuccess.dispatch,
    data
});
exports.loginPayload = loginPayload;
const verifyEmailPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailGet,
    data
});
exports.verifyEmailPayload = verifyEmailPayload;
const verifyEmailPostPayload = (data) => ({
    serviceKey: services_1.endpoints.verifyEmailPost,
    data
});
exports.verifyEmailPostPayload = verifyEmailPostPayload;
const resetPasswordPayload = (data) => ({
    serviceKey: services_1.endpoints.getResetPassword,
    successActionCreator: __1.ActionCreators.user.resetPasswordSuccess.dispatch,
    data
});
exports.resetPasswordPayload = resetPasswordPayload;
const resetPasswordChangePayload = (data) => ({
    serviceKey: services_1.endpoints.postResetPassword,
    successActionCreator: __1.ActionCreators.user.resetPasswordChangeSuccess.dispatch,
    data
});
exports.resetPasswordChangePayload = resetPasswordChangePayload;
const signUpPayload = (data) => ({
    serviceKey: services_1.endpoints.signup,
    successActionCreator: __1.ActionCreators.user.signUpSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.signUpFailure.dispatch,
    data
});
exports.signUpPayload = signUpPayload;
const hydrateUserPayload = () => ({
    serviceKey: services_1.endpoints.me,
    data: null,
    successActionCreator: __1.ActionCreators.user.hydrateUserSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.hydrateUserFailure.dispatch
});
exports.hydrateUserPayload = hydrateUserPayload;
const logoutPayload = () => ({
    serviceKey: services_1.endpoints.logoutUser,
    data: null
});
exports.logoutPayload = logoutPayload;
const ApiChangeInformation = (payload) => ({
    serviceKey: services_1.endpoints.updateMe,
    data: payload,
    successActionCreator: __1.ActionCreators.user.changeInformationSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.changeInformationFailure.dispatch,
});
exports.ApiChangeInformation = ApiChangeInformation;
const ApiChangePassword = (payload) => ({
    serviceKey: services_1.endpoints.changePassword,
    data: payload,
    successActionCreator: __1.ActionCreators.user.changePasswordSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.changePasswordFailure.dispatch,
});
exports.ApiChangePassword = ApiChangePassword;
const ApiS3UploadLogo = (payload) => ({
    serviceKey: services_1.endpoints.uploadLogo,
    data: payload,
    successActionCreator: __1.ActionCreators.user.S3UploadLogoSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.S3UploadLogoFailure.dispatch,
});
exports.ApiS3UploadLogo = ApiS3UploadLogo;
const ApiUpdateOrganizationLogo = (id = null) => ({
    serviceKey: services_1.endpoints.updateOrganization,
    data: { logo: id },
    successActionCreator: __1.ActionCreators.user.updateOrganizationLogoSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.updateOrganizationLogoFailure.dispatch
});
exports.ApiUpdateOrganizationLogo = ApiUpdateOrganizationLogo;
const ApiUpdateOrganization = (payload) => ({
    serviceKey: services_1.endpoints.updateOrganization,
    data: payload,
    successActionCreator: __1.ActionCreators.user.updateOrganizationSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.user.updateOrganizationFailure.dispatch,
});
exports.ApiUpdateOrganization = ApiUpdateOrganization;
