"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
exports.default = {
    container: (size, borderRadius, disabled, background) => core_1.css `
    height: ${size}px;
    width: ${size}px;
    background-color: ${background};
    border-radius: ${borderRadius}px;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    opacity: ${disabled ? 0.5 : 1};
  `,
    preview: (size, background, borderRadius, fontColor, fontSize) => core_1.css `
    height: ${size}px;
    width: ${size}px;
    border-radius: ${borderRadius}px;

    h2 {
      color: ${fontColor};
      font-size: ${fontSize}px;
    }
  `,
    badge: (size, backgroundColor, color, width, style) => core_1.css `
    height: ${size}px;
    width: ${size}px;
    background-color: ${backgroundColor};
    border-color: ${color};
    border-width: ${width}px;
    border-style: ${style};
    right: -${Math.floor(size / 2)}px;
  `,
    progress: (size) => core_1.css `
    width: ${Math.floor(size / 2.65)}px;
  `
};
