"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalActions = void 0;
exports.ModalActions = {
    RENDER_MODAL: 'RENDER_MODAL',
    DID_MOUNT: 'DID_MOUNT',
    WILL_UNMOUNT: 'WILL_UNMOUNT',
    REMOVE_MODAL: 'REMOVE_MODAL',
    SET_CONTENT: 'SET_CONTENT',
    SET_DETAIL_CONTENT: 'SET_DETAIL_CONTENT'
};
