"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linearProgressBarDynamicStyle = exports.linearProgressDynamicStyle = exports.circleBackgroundDynamicStyle = exports.circleProgressDynamicStyle = void 0;
const react_1 = require("@emotion/react");
const circleProgressDynamicStyle = (strokeColor, strokeDasharray, strokeDashoffset) => react_1.css `
    stroke: ${strokeColor};
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    stroke-dasharray: ${strokeDasharray};
    stroke-dashoffset: ${strokeDashoffset};
  `;
exports.circleProgressDynamicStyle = circleProgressDynamicStyle;
const circleBackgroundDynamicStyle = (strokeColor) => react_1.css `
    stroke: ${strokeColor};
    fill: none;
  `;
exports.circleBackgroundDynamicStyle = circleBackgroundDynamicStyle;
const linearProgressDynamicStyle = (width, strokeWidth, backgroundColor) => react_1.css `
    width: ${width}px;
    height: ${strokeWidth}px;
    background-color: ${backgroundColor};
    border-radius: 10px;
  `;
exports.linearProgressDynamicStyle = linearProgressDynamicStyle;
const linearProgressBarDynamicStyle = (percentage, color) => react_1.css `
    width: ${percentage}%;
    height: 100%;
    background-color: ${color};
    border-radius: 10px;
  `;
exports.linearProgressBarDynamicStyle = linearProgressBarDynamicStyle;
