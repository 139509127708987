"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
exports.default = {
    container: (width) => core_1.css `
    @media screen and (orientation: portrait) {
      width: min(${width}px, 80vh);
    }
    @media screen and (orientation: landscape) {
      width: min(${width}px, 80vw);
    }
  `,
    footer: (justifyContent, gap) => core_1.css `
    justify-content: ${justifyContent};
    gap: ${gap}px;

    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      > * + * {
        margin-left: ${gap}px;
      }
    }
  `
};
