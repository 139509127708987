"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useCycle = (options, defaultValue) => {
    const [option, setOption] = react_1.useState(defaultValue !== undefined ? options.indexOf(defaultValue) : 0);
    const onCycle = (value) => {
        if (value !== undefined && options.indexOf(value) !== -1) {
            setOption(options.indexOf(value));
        }
        else {
            setOption(option + 1 < options.length ? option + 1 : 0);
        }
    };
    return [options[option], onCycle];
};
exports.default = useCycle;
