"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = react_1.useState(getWindowDimensions());
    react_1.useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowDimensions;
};
exports.default = useWindowDimensions;
