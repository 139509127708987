"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownInputField = exports.CheckboxInputField = exports.PasswordInputField = exports.TextInputField = void 0;
const formik_1 = require("formik");
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const components_1 = require("@/components");
const constants_1 = require("@/constants");
const addFormikFieldProps = (name) => {
    const { errors, values, handleBlur, handleChange, touched, setTouched } = formik_1.useFormikContext();
    // @ts-ignore
    const error = touched[name] && errors[name];
    return {
        error,
        // @ts-ignore
        value: values[name],
        onBlur: handleBlur,
        onChange: (e) => {
            setTouched(Object.assign(Object.assign({}, touched), { [name]: true }), true);
            handleChange(e);
        },
    };
};
const TextInputField = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    return (react_1.default.createElement(components_1.Input, Object.assign({ name: name }, rest, addFormikFieldProps(name))));
};
exports.TextInputField = TextInputField;
exports.TextInputField.defaultProps = components_1.Input.defaultProps;
const PasswordInputField = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const [passwordVisible, setPasswordVisible] = react_1.useState(false);
    const onTogglePassword = () => {
        setPasswordVisible(!passwordVisible);
    };
    return (react_1.default.createElement(components_1.Input, Object.assign({}, rest, { name: name, type: passwordVisible ? 'text' : 'password', endAdornment: {
            icon: {
                default: passwordVisible ? constants_1.Images.eye : constants_1.Images.eyeClose,
                active: passwordVisible ? constants_1.Images.eyeActive : constants_1.Images.eyeCloseActive,
                error: passwordVisible ? constants_1.Images.eyeError : constants_1.Images.eyeCloseError
            },
            onClick: onTogglePassword
        } }, addFormikFieldProps(name))));
};
exports.PasswordInputField = PasswordInputField;
exports.PasswordInputField.defaultProps = components_1.Input.defaultProps;
const CheckboxInputField = (_a) => {
    var { name, ariaLabel } = _a, rest = __rest(_a, ["name", "ariaLabel"]);
    return (react_1.default.createElement(components_1.Checkbox, Object.assign({ id: name, name: name, ariaLabel: ariaLabel }, rest, addFormikFieldProps(name))));
};
exports.CheckboxInputField = CheckboxInputField;
exports.CheckboxInputField.defaultProps = components_1.Checkbox.defaultProps;
const DropdownInputField = (_a) => {
    var { name, options } = _a, rest = __rest(_a, ["name", "options"]);
    const { values, setFieldValue } = formik_1.useFormikContext();
    const onOptionChange = (value, action) => 
    // @ts-ignore
    action.action === 'select-option' ? setFieldValue(name, value.value) : {};
    return (react_1.default.createElement(components_1.Dropdown, Object.assign({ name: name, options: options }, rest, addFormikFieldProps(name), { 
        // @ts-ignore
        value: lodash_1.find(options, { value: values[name] }), onChange: onOptionChange })));
};
exports.DropdownInputField = DropdownInputField;
exports.DropdownInputField.defaultProps = components_1.Dropdown.defaultProps;
