"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getEmployeeStore = (store) => store.employees;
const getEmployees = (store) => getEmployeeStore(store).employees;
const getCount = (store) => getEmployeeStore(store).count;
const getNext = (store) => getEmployeeStore(store).next;
const getPrevious = (store) => getEmployeeStore(store).previous;
const getCurrentEmployee = (store) => getEmployeeStore(store).currentEmployee;
const getErrors = (store) => getEmployeeStore(store).errors;
const getRequestName = (store) => getEmployeeStore(store).requestEmployee;
exports.default = {
    getEmployees,
    getCount,
    getNext,
    getPrevious,
    getCurrentEmployee,
    getErrors,
    getRequestName
};
