"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const react_1 = require("react");
const private_route_1 = require("@/components/private-route");
const useScroll = (elementId = private_route_1.PRIVATE_CONTAINER) => {
    const [scrollY, setScrollY] = react_1.useState(0);
    const node = document.getElementById(elementId);
    const onScroll = lodash_1.debounce((e) => {
        // @ts-ignore
        setScrollY(e.target.scrollTop);
    }, 100);
    react_1.useEffect(() => {
        node === null || node === void 0 ? void 0 : node.addEventListener('scroll', onScroll);
        return () => node === null || node === void 0 ? void 0 : node.removeEventListener('scroll', onScroll);
    }, [node]);
    return scrollY;
};
exports.default = useScroll;
