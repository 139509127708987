"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const PanelTypes = __importStar(require("@clearsummit/digital-blueprint-models"));
exports.default = {
    general: {
        email: 'Email',
        thereWasAnError: 'There was an error processing your request.',
        password: 'Password',
        close: 'close',
        loading: 'Loading',
        okay: 'Okay',
        enterHere: 'Enter Here',
        next: 'Next',
        back: 'Back',
        home: 'Home',
        companyName: 'Digital Blueprint',
        menu: 'Menu',
        previousPage: 'Previous Page',
        nextPage: 'Next Page',
        more: 'More',
        logout: 'Log out',
        noData: 'No Data',
        booleanYes: 'Yes',
        booleanNo: 'No',
        cancel: 'Cancel',
        print: 'Print',
    },
    home: {
        greetings: 'Greetings',
        niceToSeeYou: (name) => `Nice to see you ${name}`,
        clickToReplaceName: 'Click to replace name'
    },
    login: {
        pleaseEnterAValid: 'Please enter a valid email address.',
        incorrectPassword: 'Incorrect password.',
        welcomeBack: 'Welcome Back',
        enterEmail: 'Email',
        enterPassword: 'Password',
        welcome: 'Welcome to Digital Blueprint',
        signIn: 'Sign In',
        signUp: 'Sign Up',
        logOut: "Log Out",
        description: 'Log in to start optimizing your electrical network.',
        forgotPassword: 'Forgot Password?',
        dontHaveAnAccount: "Don't Have An Account?"
    },
    signup: {
        pleaseEnterAValidEmail: 'Please enter a valid email address.',
        passwordsDoNotMatch: 'Passwords do not match.',
        beAtLeast8: 'Be at least 8 characters',
        notExceed50: 'Not exceed 50 characters',
        containAtLeast1Number: 'Contain at least one number',
        containAtLeast1Special: 'Contain at least one special character',
        welcomeToClearSummit: 'Welcome to ClearSummit',
        createAccount: 'Create your account',
        signUpToGet: 'Sign up to get started',
        passwordMustBe: 'Enter your password below. Use at least 8 characters. You can use uppercase, lowercase and symbols.',
        confirmPassword: 'Confirm Password',
        getStarted: 'Get Started!',
        inputEmail: 'Input Email Here',
        iAcceptThe: 'I accept Digital Blueprint’s ',
        termsAndConditions: 'Terms and Conditions of Use.',
        firstName: 'First Name',
        lastName: 'Last Name',
        username: 'username',
        email: 'Email',
        password: 'Password',
        signUp: 'Sign Up',
        alreadyHaveAnAccount: 'Already have an account? ',
        login: 'Login'
    },
    resetPassword: {
        title: 'Create a new password',
        description: 'Enter your new password below. Use at least 8 characters. Your can use uppercase, lowercase and symbols.',
        forgotYourPassword: 'Forgot Your Password?',
        pleaseEnterYourEmail: 'Please enter your email below to receive your password reset instructions.',
        ifYouHaveAnAccount: 'If you have an account, a reset link was sent to your email.',
        resetYourPassword: 'Reset Your Password',
        pleaseEnterYourNewPassword: 'Please enter your new password.',
        resetPassword: 'Reset Password',
        passwordChangedSuccessfully: 'Password changed successfully. Please log in to continue',
        enterNewPassword: 'New password',
        confirmNewPassword: 'Confirm password',
        sendMeInstructions: "Send me the instructions",
        saveNewPassword: 'Save new password',
        loginLink: 'Log In',
    },
    changePassword: {
        oldPassword: 'Old Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        changePassword: 'Change Password'
    },
    instructions: {
        title: 'Instructions sent!',
        description: 'If you have a Digital Blueprint account, you will receive a reset link with instructions to create a new password.',
        goBackToSignIn: 'Go back to log in'
    },
    verifyEmail: {
        verifyEmail: 'Verify email address',
        weveSentAnEmailToVerify: `We've sent an email to verify your address.  Please check your email and follow the link.`,
        resendEmail: 'Resend email'
    },
    verifyEmailConfirm: {
        verifyEmailAddress: 'Verify email address',
        thanksforValidatingYourEmail: `Thanks for validating your email.`
    },
    changePasswordSuccess: {
        changePasswordConfirmation: 'Change Password Confirmation',
        youveHaveSuccessfullyChanged: 'You have successfully changed your password.'
    },
    PanelTypes: {
        [PanelTypes.ColorSchemes.BLACK_RED_BLUE]: 'Black/Red/Blue',
        [PanelTypes.ColorSchemes.BLUE_RED_BLACK]: 'Blue/Red/Black',
        [PanelTypes.ColorSchemes.BROWN_ORANGE_YELLOW]: 'Brown/Orange/Yellow',
        [PanelTypes.FeederSizeLugs.FEED_THROUGH]: 'Feed Through',
        [PanelTypes.FeederSizeLugs.SUB_FEED]: 'Sub Feed',
        [PanelTypes.FeederSizeTypes.BOTTOM_FED]: 'Bottom Fed',
        [PanelTypes.FeederSizeTypes.TOP_FED]: 'Top Fed',
        [PanelTypes.FuseSizeUnits.AMP]: 'Amp',
        [PanelTypes.FuseSizeUnits.VOLT]: 'Volt',
        [PanelTypes.ParentBreakers.MLO]: 'MLO',
        [PanelTypes.ParentBreakers.MAIN_BREAKER]: 'Main Breaker',
        [PanelTypes.PowerTypes.NORMAL]: 'Normal',
        [PanelTypes.PowerTypes.EMERGENCY]: 'Emergency',
        [PanelTypes.Statuses.APPROVED]: 'Approved',
        [PanelTypes.Statuses.PENDING]: 'Pending',
        [PanelTypes.TemplateSizes.CKT_12]: '12 Ckt Panel',
        [PanelTypes.TemplateSizes.CKT_18]: '18 Ckt Panel',
        [PanelTypes.TemplateSizes.CKT_30]: '30 Ckt Panel',
        [PanelTypes.TemplateSizes.CKT_42]: '42 Ckt Panel',
        [PanelTypes.TemplateSizes.CKT_60]: '60 Ckt Panel',
        [PanelTypes.TemplateSizes.CKT_84]: '84 Ckt Panel',
        [PanelTypes.VoltagePhases.VP_120_208]: '120/208Y-3Ø',
        [PanelTypes.VoltagePhases.VP_208]: '208Y-3Ø',
        [PanelTypes.VoltagePhases.VP_277_480]: '277/480Y-3Ø',
        [PanelTypes.VoltagePhases.VP_120_240]: '120/240Δ-3Ø',
        [PanelTypes.VoltagePhases.VP_240]: '1240Δ-3Ø',
        [PanelTypes.VoltagePhases.VP_480]: '480Δ-3Ø',
        undefined: 'N/A',
    },
    panelDetail: {
        changeRequests: 'Change Requests',
        panelOptions: 'Panel Options',
        panelApprove: 'Approve',
        sectionBasicInfo: 'Basic information',
        sectionLocation: 'Location',
        sectionPanelFeeder: 'Panel Feeder information',
        sectionOthers: 'Others',
        nameLabel: 'Panel Name',
        templateSizeLabel: 'Panel Template Size',
        buildingNameLabel: 'Building Name',
        floorNumberLabel: 'Floor Number',
        feederSizeLabel: 'Feeder Size',
        feederSizeUnitLabel: 'Feeder Size Unit',
        fedFromDistributionLabel: 'Fed from Distribution',
        colorSchemeLabel: 'Color Scheme',
        conduitSizeLabel: 'Conduit Size',
        feederSizeTypeLabel: 'Fed',
        feederSizeLugsLabel: 'Feed Type',
        fuseSizeLabel: 'Fuse Size',
        fuseSizeUnitLabel: 'Fuse Size Unit',
        voltagePhaseLabel: 'Voltage and Phase',
        powerTypeLabel: 'Power Type',
        parentBreakerLabel: 'MLO or Main Breaker',
        aicRatingLabel: 'AIC Rating',
        panelRatingLabel: 'Panel Rating',
        tabLayout: 'Panel',
        tabInfo: 'Panel information',
        menuPrint: 'Print',
        menuShare: 'Share',
        menuDownload: 'Download',
        menuDelete: 'Delete',
        qrSection: 'QR Code',
        shareTitle: (name) => `Share ${name}`,
        shareMessage: 'Copy the link below to share this panel. Anyone with this link can view and print the panel & QR code.',
        shareCopy: 'Copy link',
        shareCancel: 'Cancel',
        shareDone: 'Done',
        shareCopySuccess: 'Copied to Clipboard',
    },
    panelList: {
        pageTitle: 'Panels',
        modalClear: 'Clear All',
        modalApply: 'Apply',
        modalFilterName: 'Panel Filter Modal',
        modalFilterTitle: 'Filter By',
        modalFilterVoltage: 'Voltage and Phase',
        modalFilterPower: 'Power type',
        columnName: 'Panel Name',
        columnUpdated: 'Date Updated',
        columnUpdatedSort: 'Toggle Sort by Date Updated',
        columnStatus: 'Status',
        panelApproved: 'Panel was approved successfully',
        panelDeleted: 'Panel was deleted successfully',
        dropdownAll: 'All Panels',
        dropdownChanges: 'Change requests',
        dropdownNew: 'New panel requests',
    },
    changeList: {
        changeType: 'Change Type',
        dateCreated: 'Date Created',
        status: 'Status',
        actions: 'Actions',
        approved: 'Approved',
        pending: 'Pending Approval',
        denied: 'Denied',
        metadataType: 'Circuit',
        panelType: 'Panel',
        layoutType: 'Layout',
        panelIcon: 'Panel Icon',
        changeRequests: 'Change Requests',
        buttonApprove: 'Approve',
        buttonDeny: 'Deny',
        modalApproveTitle: 'Approve changes',
        modalDenyTitle: 'Deny changes',
        modalPlaceholder: 'Add a description...',
        modalApproveButton: 'Approve',
        modalDenyButton: 'Deny',
        changeReviewed: 'Your change review was sent successfully',
    },
    changeHistory: {
        metadataOption: 'Information changes on the circuit',
        layoutOption: 'Panel layout changes',
        panelOption: 'Settings changed on the panel schedule',
        approvedOption: 'Approved',
        deniedOption: 'Denied',
        changeTypeDropdown: 'Change type',
        statusDropdown: 'Status',
        clearFilters: 'Clear all',
        applyFilters: 'Apply',
        tableTitle: 'Version history',
        filtersLabel: 'Filters',
        columnType: 'Change type',
        columnCreated: 'Date created',
        columnStatus: 'Status',
        breadcrumbTitle: 'History',
    },
    changeDetail: {
        breadcrumbTitle: 'Detail',
        tabPanel: 'Panel',
        tabDetails: 'Details',
        currentVersion: 'Current Version',
        previousVersion: 'Previous Version',
        viewMode: 'View mode',
        labelCreated: 'Created',
        labelReviewed: 'Reviewed',
        labelChangedBy: 'Change made by',
        labelNotes: 'Notes',
        previousValue: 'Previous Value',
        currentValue: 'Current Value',
        newValue: 'New Value',
        changeValue: 'Suggested Value',
    },
    panelDownloadModal: {
        modalTitle: (name) => `Download ${name}`,
        modalBody: 'Choose the format',
        excel: 'Excel',
        pdf: 'PDF',
    },
    panelPrintModal: {
        title: (name) => `Print ${name}`,
        label: 'Print options',
        printBoth: 'Panel schedule & QR code',
        printSchedule: 'Only Panel schedule',
        printQR: 'Only QR code'
    },
    settings: {
        pageTitle: 'Settings',
        logoSectionTitle: 'Account preference',
        logoSectionSubtitle: 'Upload the Organization\'s logo',
        logoPlaceholder: 'Logo',
        sectionSave: 'Save changes',
        sectionCancel: 'Cancel',
        sectionEdit: 'Edit',
        infoSectionTitle: 'Personal information',
        infoSectionSubtitle: 'Manage your information',
        infoName: 'Name',
        infoFirstName: 'First Name',
        infoLastName: 'Last Name',
        infoEmail: 'Email',
        passwordSectionTitle: 'Password',
        passwordSectionSubtitle: 'Set a unique password to protect your personal Digital Blueprint account.',
        passwordEdit: 'Change password',
        newPassword: 'New password',
        oldPassword: 'Current password',
        confirmPassword: 'Confirm password',
        passwordLabel: 'Password',
        changeInformationSuccess: 'Personal information was successfully updated.',
        changePasswordSuccess: 'Your Password was successfully updated.',
        changeLogoSuccess: 'Organization logo was successfully updated.',
        changeLogoFailure: 'There was a problem updating the Organization logo.',
        S3UplodadLogoFailure: 'There was a problem uploading the logo to S3. Please try again.',
    },
    employeeRoles: {
        DEPARTMENT_LEAD: 'Department Lead',
        HOUSE_ENGINEER: 'House Engineer',
        SENIOR_CONTRACTOR: 'Senior Contractor',
        CONTRACTOR: 'Contractor',
        undefined: 'N/A'
    },
    employeeList: {
        pageTitle: 'Team',
        columnName: 'Name',
        columnEmail: 'Email',
        columnRole: 'Role',
        labelEdit: 'Edit',
        labelDelete: 'Delete',
        labelCancel: 'Cancel',
        labelDone: 'Done',
        buttonNewEmployee: 'New team member',
        deleteModalTitle: (name) => `Are you sure you want to delete ${name} permanently?`,
        deleteModalLabel: 'Delete Panel Modal',
        deleteModalMessage: 'If you delete this team member, they will lose all access to Digital Blueprint.',
        permissionsModalTitle: 'Team Member Permissions',
        permissionsModalAction: 'Action',
        permissionsModalContractor: 'Contractor',
        permissionsModalSenior: 'Senior Contractor',
        permissionsModalHouseEngineer: 'House Engineer',
        permissionsModalAccessCreateEdit: 'Access/Create/Edit all electrical panels',
        permissionsModalEditPanel: 'Edit panels information',
        permissionsModalDeletePanel: 'Delete panels',
    },
    employeeDetail: {
        pageTitleNew: 'New team member',
        pageTitleEdit: (name) => `Edit ${name}`,
        labelFirstName: 'First Name',
        labelLastName: 'Last Name',
        labelEmail: 'Email',
        labelPhone: 'Phone',
        labelRole: 'Role',
        labelJobTitle: 'Job Title',
        breadcrumbTeam: 'Team',
        breadcrumbNew: 'New team member',
        breadcrumbEdit: 'Edit',
        roleDepartmentLead: 'Department Lead',
        roleHouseEngineer: 'House Engineer',
        roleSeniorContractor: 'Senior Contractor',
        roleContractor: 'Contractor',
        roleInfo: 'More info',
        addEmployee: 'Add',
        editEmployee: 'Update',
        invalidPhone: 'This doesn\'t look like a valid phone number',
        toastCreated: (name) => `${name} was successfully saved`,
        toastUpdated: (name) => `${name} was successfully updated`,
        toastDeleted: (name) => `${name} was successfully deleted`,
        defaultUserName: 'User',
        notFound: 'The id specified was not found.',
    },
    page404: {
        message: 'We can’t seem to find the page you’re looking for.',
        reload: 'Reload page',
        goBack: 'Take me back',
    }
};
