"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breadcrumbSeparatorDynamicStyle = exports.breadcrumbItemDynamicStyle = exports.breadcrumbDynamicStyle = void 0;
const react_1 = require("@emotion/react");
const breadcrumbDynamicStyle = (gap) => react_1.css `
    display: flex;
    gap: ${gap};

    @supports (-webkit-touch-callout: none) and (not(translate: none)) {
      > * + * {
        margin-left: ${gap};
      }
    }
  `;
exports.breadcrumbDynamicStyle = breadcrumbDynamicStyle;
const breadcrumbItemDynamicStyle = (isCurrentPage, color, colorSelected) => react_1.css `
    color: ${isCurrentPage ? colorSelected : color};
    a {
      font-weight: ${isCurrentPage ? 'bold' : 'normal'};
    }
  `;
exports.breadcrumbItemDynamicStyle = breadcrumbItemDynamicStyle;
const breadcrumbSeparatorDynamicStyle = (gap, color) => react_1.css `
  padding-left: ${gap};
  color: ${color};
`;
exports.breadcrumbSeparatorDynamicStyle = breadcrumbSeparatorDynamicStyle;
