"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmployeeDetailFieldNames = exports.ChangePasswordFieldNames = exports.ChangeInformationFieldNames = exports.SignUpFieldNames = exports.ResetPasswordChangeFieldNames = exports.ResetPasswordFieldNames = exports.LoginFieldNames = void 0;
var LoginFieldNames;
(function (LoginFieldNames) {
    LoginFieldNames["username"] = "username";
    LoginFieldNames["password"] = "password";
})(LoginFieldNames = exports.LoginFieldNames || (exports.LoginFieldNames = {}));
var ResetPasswordFieldNames;
(function (ResetPasswordFieldNames) {
    ResetPasswordFieldNames["username"] = "username";
})(ResetPasswordFieldNames = exports.ResetPasswordFieldNames || (exports.ResetPasswordFieldNames = {}));
var ResetPasswordChangeFieldNames;
(function (ResetPasswordChangeFieldNames) {
    ResetPasswordChangeFieldNames["password"] = "password";
    ResetPasswordChangeFieldNames["confirmPassword"] = "confirmPassword";
})(ResetPasswordChangeFieldNames = exports.ResetPasswordChangeFieldNames || (exports.ResetPasswordChangeFieldNames = {}));
var SignUpFieldNames;
(function (SignUpFieldNames) {
    SignUpFieldNames["token"] = "token";
    SignUpFieldNames["password"] = "password";
    SignUpFieldNames["tos"] = "accepted_tos";
})(SignUpFieldNames = exports.SignUpFieldNames || (exports.SignUpFieldNames = {}));
var ChangeInformationFieldNames;
(function (ChangeInformationFieldNames) {
    ChangeInformationFieldNames["firstName"] = "first_name";
    ChangeInformationFieldNames["lastName"] = "last_name";
    ChangeInformationFieldNames["email"] = "email";
})(ChangeInformationFieldNames = exports.ChangeInformationFieldNames || (exports.ChangeInformationFieldNames = {}));
var ChangePasswordFieldNames;
(function (ChangePasswordFieldNames) {
    ChangePasswordFieldNames["oldPassword"] = "old_password";
    ChangePasswordFieldNames["newPassword"] = "new_password";
    ChangePasswordFieldNames["confirmPassword"] = "confirm_password";
})(ChangePasswordFieldNames = exports.ChangePasswordFieldNames || (exports.ChangePasswordFieldNames = {}));
var EmployeeDetailFieldNames;
(function (EmployeeDetailFieldNames) {
    EmployeeDetailFieldNames["firstName"] = "first_name";
    EmployeeDetailFieldNames["lastName"] = "last_name";
    EmployeeDetailFieldNames["email"] = "email";
    EmployeeDetailFieldNames["phone"] = "phone";
    EmployeeDetailFieldNames["role"] = "role";
    EmployeeDetailFieldNames["jobTitle"] = "job_title";
})(EmployeeDetailFieldNames = exports.EmployeeDetailFieldNames || (exports.EmployeeDetailFieldNames = {}));
