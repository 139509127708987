"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getPanelStore = (store) => store.panel;
const getPanels = (store) => getPanelStore(store).panels;
const getCount = (store) => getPanelStore(store).count;
const getFilters = (store) => getPanelStore(store).filters;
const getCurrentPanel = (store) => getPanelStore(store).currentPanel;
exports.default = {
    getPanels,
    getCount,
    getFilters,
    getCurrentPanel
};
