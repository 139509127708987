"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@emotion/core");
exports.default = {
    container: (hideSearchBar) => core_1.css `
    ${hideSearchBar && `
        justify-content: flex-end;
        padding: 24px;
      `}`,
    dropdownMenu: () => core_1.css `
    z-index: 1;
  `
};
