"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiApprovePanel = exports.ApiGetPanel = exports.ApiDeletePanel = exports.ApiListPanels = void 0;
const services_1 = require("@/helpers/services");
const __1 = require("..");
// eslint-disable-next-line camelcase
const ApiListPanels = (payload) => {
    const { perPage } = payload, data = __rest(payload, ["perPage"]);
    return {
        data: Object.assign(Object.assign({}, data), { page_size: perPage }),
        serviceKey: services_1.endpoints.listPanels,
        successActionCreator: __1.ActionCreators.panel.listPanelsSuccess.dispatch
    };
};
exports.ApiListPanels = ApiListPanels;
const ApiDeletePanel = (data) => ({
    data,
    serviceKey: services_1.endpoints.deletePanel,
    successActionCreator: __1.ActionCreators.panel.deletePanelSuccess.dispatch
});
exports.ApiDeletePanel = ApiDeletePanel;
const ApiGetPanel = (data) => ({
    data,
    serviceKey: services_1.endpoints.getPanel,
    successActionCreator: __1.ActionCreators.panel.getPanelSuccess.dispatch,
    errorActionCreator: __1.ActionCreators.panel.getPanelFailure.dispatch
});
exports.ApiGetPanel = ApiGetPanel;
const ApiApprovePanel = (data) => ({
    data,
    serviceKey: services_1.endpoints.approvePanel,
    successActionCreator: __1.ActionCreators.panel.approvePanelSuccess.dispatch
});
exports.ApiApprovePanel = ApiApprovePanel;
